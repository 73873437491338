<template>
  <el-popover width="280" trigger="click" popper-class="group-popover" v-model="visible">
    <label>{{ title }}</label>
    <el-input placeholder="请输入分组名称" v-model="groupName" maxlength="10" show-word-limit>
    </el-input>
    <div class="group-popover__footer">
      <div class="group-popover__confirm" @click="onConfirm">保存
      </div>
      <div class="group-popover__cancel" @click="visible=false">取消</div>
    </div>
    <template slot="reference">
      <slot/>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: "popoverGroup",
  props: {
    title: String,
  },
  data() {
    return {
      visible: false,
      groupName: ''
    }
  },
  methods: {
    onConfirm() {
      if (this.groupName.trim()) {
        this.$emit('confirm', this.groupName)
        this.groupName = ''
        this.visible = false
      } else {
        this.$message.warning('名称不允许为空')
        this.groupName = ''
      }
    }
  }
}
</script>

<style lang="scss">
.group-popover {
  display: flex;
  flex-direction: column;
  padding: 20px 28px;
  color: #333333;
  font-size: 15px;
}
</style>
<style lang="scss" scoped>
.group-popover {

  label {
    display: inline-block;
    width: 100%;
    color: #999999;
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
  }

  &__confirm {
    width: 91px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 4px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }

  &__cancel {
    width: 91px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #333333;
    font-size: 15px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    margin-left: 12px;

    &:hover {
      background-color: #F6F6F6;
    }
  }
}
</style>
