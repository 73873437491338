<template>
  <el-dialog
      width="426px"
      top="50px"
      custom-class="insert-dialog"
      :visible.sync="visible"
      :show-close="false"
      :before-close="onClose">
    <div class="insert-dialog__search">
      <h4>添加学生</h4>
      <el-input v-model="studentName"
                class="insert-dialog__input"
                placeholder="输入学生姓名搜索"
                suffix-icon="el-icon-search"
                @input="onChangeTableData"
                size="large"/>
    </div>
    <el-checkbox-group v-model="checkList">
      <el-checkbox
          v-for="item of tableData"
          :key="item.id"
          :label="item.id">
        <highlight-text-view font-size="15px" :title="item.realName" :keyword="studentName"/>
      </el-checkbox>
    </el-checkbox-group>
    <div class="insert-dialog__line"></div>
    <div class="insert-dialog__footer">
      <div :class="{'confirm-disable':checkList.length===0}"
           class="insert-dialog__confirm" @click="onConfirm">确定
      </div>
      <div class="insert-dialog__cancel" @click="onClose">取消</div>
    </div>
  </el-dialog>
</template>

<script>
import {getStudents} from "@/api/classes";
import {addStudentsToGroup} from "@/api/group";
import HighlightTextView from '@/components/HighlightTextView'

export default {
  name: "dialogInsertStudent",
  components: {HighlightTextView},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    classId: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      studentName: '',
      checkList: [],
      studentList: [],
      surplusStudentList: [],
      tableData: []
    }
  },
  watch: {
    students() {
      this.surplusStudentList = this.studentList.filter(item => !this.students.some(i => i.userId === item.id))
      this.tableData = this.surplusStudentList
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        const res = await getStudents(this.classId)
        if (res.state === '11') {
          this.studentList = res.data.userList
          this.surplusStudentList = res.data.userList.filter(item => !this.students.some(i => i.userId === item.id))
          this.tableData = this.surplusStudentList
        }
      } catch (e) {
      }
    },
    /**
     * 条件搜索
     */
    onChangeTableData() {
      this.tableData = this.surplusStudentList.filter(item => {
        if (this.studentName) {
          return item.realName.includes(this.studentName) || this.checkList.includes(item.id)
        } else {
          return item
        }
      })
    },
    async onConfirm() {
      try {
        const res = await addStudentsToGroup(this.checkList.join(), this.groupId)
        if (res.state === '11') {
          this.$message.success('添加成功')
          this.$emit('updated')
          this.onClose()
        }
      } catch (e) {
      }
    },
    onClose() {
      this.checkList = []
      this.studentName = ''
      this.onChangeTableData()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.insert-dialog {
  border-radius: 16px;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 0 28px;
  }

  .el-checkbox {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 14px;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background-color: #f6f6f6;
    border-radius: 6px;

    &::after {
      width: 4px !important;
      height: 8px !important;
      left: 6px !important;
      top: 3px !important;
      border-width: 2px;
    }
  }

  .el-checkbox__label {
    color: #333333;
    font-size: 15px;
    line-height: 22px;
    font-weight: normal;
  }

}
</style>
<style lang="scss" scoped>
.insert-dialog {

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 28px 10px;

    h4 {
      color: #333333;
      font-size: 17px;
    }
  }

  &__input {
    width: 220px;
    font-size: 15px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    padding-bottom: 4px;
  }

  &__confirm {
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 4px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }

  &__cancel {
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 15px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    margin-left: 56px;

    &:hover {
      background-color: #F1F1F1;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #F1F1F1;
  }
}

.confirm-disable {
  cursor: not-allowed;
  background: #C0E0FB !important;
}

</style>
