<template>
  <div class="group-container" v-loading="loading">
    <RouterNav name="分组管理"/>
    <template v-if="groupList.length">
      <div class="group-content">
        <div class="group-content__left">
          <popover-group title="分组名称" @confirm="onCreateGroup">
            <div class="group-content__left-create">
              <img src="../../assets/icon-add-blue.png" alt="新建分组"/>
              新建分组
            </div>
          </popover-group>
          <div class="group-content__left-group-item"
               :class="{'group-item--active':currentGroupId===item.id}"
               v-for="(item, index) of groupList" :key="item.id"
               @click="onChangeGroupItem(item, index)">
            <span class="name">{{ item.name }}</span><span>（{{ item.studentCount }}）</span>
          </div>
        </div>
        <div class="group-content__right">
          <div class="group-content__right-top">
            <h4>{{ groupList[currentGroupIndex].name }}</h4>
            <popover-group title="分组重命名" @confirm="onUpdateGroupName">
              <div class="group-content__right-top-square col-333">
                <img src="../../assets/input.png" alt="重命名"/>
                重命名
              </div>
            </popover-group>
            <div class="group-content__right-top-square col-FF3C30" @click="onDeleteGroup">
              <img src="../../assets/delete.png" alt="删除"/>
              删除
            </div>
            <p style="flex: 1;"></p>
            <div :class="{'no-selected':!isSelected}"
                 class="group-content__right-top-round col-FF3C30"
                 @click="onRemoveStudents">
              <img src="../../assets/icon-remove-round.png" alt="remove"/>
              移除学生
            </div>
            <div class="group-content__right-top-round col-333" @click="insertStudentDialogVisible=true">
              <img src="../../assets/icon-add-round.png" alt="add"/>
              添加学生
            </div>
          </div>
          <template v-if="students.length">
            <dl class="table-row">
              <dt style="width: 64px;justify-content: flex-end;">
                <el-checkbox v-model="allSelected" @change="onSelectedAll"></el-checkbox>
              </dt>
              <dt style="width: 140px;padding-left: 10px;">学生姓名</dt>
              <dt style="flex: 1"></dt>
              <dt style="width: 356px;justify-content: center;">分组</dt>
            </dl>
            <dl class="table-row" v-for="item of students" :key="item.id">
              <dd style="width: 64px;justify-content: flex-end" @click="item.selected=!item.selected">
                <el-checkbox v-model="item.selected" @change="item.selected=!item.selected"></el-checkbox>
              </dd>
              <dd style="width: 140px;padding-left: 10px;" @click="item.selected=!item.selected">{{
                  item.realName
                }}
              </dd>
              <dd style="flex: 1" @click="item.selected=!item.selected"></dd>
              <dd style="width: 356px;justify-content: center; cursor: default;">
                <select-group width="300" :class-id="classId" :student-id="item.userId"
                              :all-groups="groupList" :groups="item.groupList"
                              @updated="loadGroupData"/>
              </dd>
            </dl>
          </template>
          <div class="group-content__right-empty" v-else>
            当前分组暂无学生~
          </div>
        </div>
      </div>
    </template>
    <div class="guide-group-create" v-else>
      <div class="icon-group-none"></div>
      <p>当前班级暂无分组，先新建分组吧～</p>
      <p style="margin-bottom: 50px;">通过对学生分组，您可以实现给不同学生布置个性化作业</p>
      <popover-group title="分组名称" @confirm="onCreateGroup">
        <el-button class="btn-create" type="primary" size="large"
                   icon="el-icon-circle-plus-outline">新建分组
        </el-button>
      </popover-group>
    </div>

    <dialog-insert-student
        :class-id="classId"
        :group-id="currentGroupId"
        :students="students"
        :visible="insertStudentDialogVisible"
        @close="insertStudentDialogVisible=false"
        @updated="loadGroupData"/>
  </div>
</template>

<script>
import PopoverGroup from "@/views/classes/components/popoverGroup";
import {
  createGroup,
  deleteGroup,
  getGroupList,
  getStudentsForGroup,
  removeStudentsForGroup,
  renameGroup
} from "@/api/group";
import RouterNav from "@/components/RouterNav";
import SelectGroup from "@/views/classes/components/selectGroup";
import DialogInsertStudent from "@/views/classes/components/dialogInsertStudent";

export default {
  name: "ClassGroup",
  components: {DialogInsertStudent, SelectGroup, RouterNav, PopoverGroup},
  data() {
    return {
      loading: false,
      insertStudentDialogVisible: false,
      classId: '',
      currentGroupId: '',
      currentGroupIndex: 0,
      groupList: [],
      students: [],
      allSelected: false,
    }
  },
  computed: {
    isSelected() {
      // 利用计算属性判断是否全选逻辑
      this.allSelected = this.students.length === 0 ? false : this.students.every(item => item.selected)

      return this.students.some(item => item.selected) || this.allSelected
    }
  },
  async created() {
    this.classId = this.$route.params.id

    this.loading = true
    await this.loadGroupData()
    this.loading = false
  },
  methods: {
    async loadGroupData() {
      try {
        const res = await getGroupList(this.classId)
        if (res.state === '11') {
          this.groupList = res.data
          if (res.data.length) {
            this.currentGroupId = res.data[this.currentGroupIndex].id

            await this.loadStudentsData()
          }
        }
      } catch (e) {
      }
    },
    async loadStudentsData() {
      try {
        const res = await getStudentsForGroup(this.currentGroupId)
        if (res.state === '11') {
          this.students = res.data.map(item => {
            item.selected = false
            return item
          })
        }
      } catch (e) {
      }
    },
    async onCreateGroup(val) {
      try {
        const res = await createGroup(val, this.classId)
        if (res.state === '11') {
          this.$message.success('分组新建成功')
          // 刷新数据
          await this.loadGroupData()
        }
      } catch (e) {
      }
    },
    async onUpdateGroupName(val) {
      try {
        const res = await renameGroup(this.currentGroupId, val)
        if (res.state === '11') {
          this.$message.success('分组重命名成功')
          // 刷新数据
          await this.loadGroupData()
        }
      } catch (e) {
      }
    },
    /**
     * 切换分组
     * @param {Object} item
     * @param {Number} index
     */
    onChangeGroupItem(item, index) {
      if (this.currentGroupIndex === index) return
      this.currentGroupId = item.id
      this.currentGroupIndex = index

      this.loadStudentsData()
    },
    /**
     * 删除分组
     */
    onDeleteGroup() {
      this.$confirm('确定删除分组数据？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteGroup(this.currentGroupId)
        if (res.state === '11') {
          this.$message.success('已成功删除该分组')
          // 切换分组
          if (this.currentGroupIndex > 0) this.currentGroupIndex--
          // 刷新数据
          await this.loadGroupData()
        }
      }).catch(() => {
      })
    },
    /**
     * 全选学生
     */
    onSelectedAll() {
      this.students = this.students.map(item => {
        item.selected = this.allSelected
        return item
      })
    },
    /**
     * 移除分组内的学生
     */
    onRemoveStudents() {
      if (!this.isSelected) return
      this.$confirm('确认将所选学生移出分组？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const ids = this.students.filter(item => item.selected).map(item => item.id).join()
        const res = await removeStudentsForGroup(ids)
        if (res.state === '11') {
          this.$message.success('已成功移出分组')
          // 刷新数据
          await this.loadGroupData()
        }
      }).catch(() => {
      })
    },
  }
}
</script>

<style lang="scss">
.table-row .el-checkbox__inner {
  width: 20px;
  height: 20px;
  background-color: #f6f6f6;
  border-radius: 6px;

  &::after {
    width: 4px !important;
    height: 8px !important;
    left: 6px !important;
    top: 3px !important;
    border-width: 2px;
  }
}
</style>
<style lang="scss" scoped>
.group-content {
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(100vh - 124px);

  &__left {
    flex-shrink: 0;
    width: 278px;
    padding: 28px;
    margin-right: 1px;
    background-color: #FFFFFF;

    &-create {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 222px;
      height: 40px;
      color: #309af2;
      font-size: 15px;
      background-color: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #309AF2;
      margin-bottom: 28px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      &:hover {
        background-color: #f6f6f6;
      }
    }

    &-group-item {
      display: flex;
      width: 222px;
      height: 50px;
      line-height: 50px;
      color: #333333;
      font-size: 15px;
      font-weight: bold;
      padding: 0 26px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f6;
      }

      .name {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .group-item--active {
      background-color: #f6f6f6;
    }
  }

  &__right {
    flex: 1;
    padding: 0 28px;
    background-color: #FFFFFF;

    &-top {
      display: flex;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #E5E5E5;
      margin-bottom: 10px;

      h4 {
        color: #333333;
        font-size: 17px;
      }

      &-square {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        font-size: 12px;
        -webkit-transform: scale(.84);
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        background-color: #FFFFFF;
        margin-left: 10px;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
        }

        &:hover {
          background-color: #F6F6F6;
        }
      }

      .no-selected {
        opacity: 30%;
        cursor: not-allowed;
      }

      &-round {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 34px;
        font-size: 15px;
        border-radius: 17px;
        border: 1px solid #E5E5E5;
        background-color: #FFFFFF;
        margin-left: 14px;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }

        &:hover {
          background-color: #F6F6F6;
        }
      }
    }

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 760px;
      color: #333333;
      font-size: 15px;
    }
  }
}

.guide-group-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1100px;
  height: calc(100vh - 124px);
  line-height: 34px;
  background-color: #ffffff;

  .icon-group-none {
    width: 173px;
    height: 155px;
    background: url("../../assets/icon-classes-none.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30px;
  }
}

.btn-create {
  width: 138px;
  border: none;
  font-size: 15px;
  background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);

  &:hover {
    background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
  }
}

.table-row {
  display: flex;
  align-items: center;

  dt {
    color: #666666;
    background-color: #f6f6f6;
  }

  dt, dd {
    display: flex;
    align-items: center;
    min-height: 50px;
  }

  dd {
    cursor: pointer;
  }

  &:hover {
    background-color: #f6f6f6;
  }
}
</style>
